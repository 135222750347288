@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

a {
  transition: all .3s ease;
}

a:hover {
  opacity: 1;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 10px;
  padding-right: 10px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 80px;
}

@media (max-width: 834px) {
  #contents_wrap {
    margin-bottom: 60px;
  }
}

#contents {
  width: 100%;
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  min-width: auto !important;
  position: relative;
}

#global_header .header {
  width: 100%;
  border-top: 10px solid #6fbbf4;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 834px) {
  #global_header .header {
    border-top: 5px solid #6fbbf4;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
    height: 80px;
    padding-left: 0;
    padding-right: 0;
  }
}

#global_header .header .header-inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

#global_header .header .header-inner .header-top {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top {
    margin-top: 15px;
  }
}

#global_header .header .header-inner .header-top h1 {
  display: flex;
  align-items: center;
  text-align: left;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top h1 {
    margin-left: 10px;
  }
}

#global_header .header .header-inner .header-top h1 img {
  margin-right: 10px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top h1 img {
    width: 45px;
  }
}

#global_header .header .header-inner .header-top h1 a {
  color: #333;
  font-size: 22px;
  line-height: 28px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top h1 a {
    font-size: 18px;
    line-height: 24px;
  }
}

#global_header .header .header-inner .header-top h1 span {
  display: block;
}

#global_header .header .header-inner .header-top .header-contact .header-time {
  text-align: right;
  font-size: 14px;
  margin-bottom: 7px;
  margin-right: 5px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top .header-contact .header-time {
    display: none;
  }
}

#global_header .header .header-inner .header-top .header-contact a {
  display: block;
  color: #fff;
  background-color: #ffc000;
  width: 280px;
  height: 65px;
  line-height: 65px;
  border-radius: 35px;
  font-size: 20px;
  transition: all .3s ease;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top .header-contact a {
    width: 80px;
    height: 80px;
    position: fixed;
    right: 80px;
    top: 0;
    border-radius: 0px;
    line-height: 80px;
  }
}

#global_header .header .header-inner .header-top .header-contact a .fa-phone {
  margin-right: 20px;
  font-size: 20px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top .header-contact a .fa-phone {
    margin-right: 0;
  }
}

#global_header .header .header-inner .header-top .header-contact a .fa-chevron-right {
  margin-left: 20px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top .header-contact a .fa-chevron-right {
    display: none;
  }
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-top .header-contact a span {
    display: none;
  }
}

#global_header .header .header-inner .header-top .header-contact a:hover {
  background-color: #fff;
  color: #ffc000;
  border: 3px solid #ffc000;
  line-height: 62px;
  opacity: 1;
}

#global_header .header .header-inner .header-nav {
  width: 100%;
  margin-top: 30px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-nav {
    margin-top: 80px;
    position: fixed;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: -100vw;
    z-index: 998;
    transition: all .3s ease;
  }
}

#global_header .header .header-inner .header-nav ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-nav ul {
    flex-wrap: wrap;
    margin-top: 30px;
  }
}

#global_header .header .header-inner .header-nav ul li {
  width: 100%;
  border-left: 3px dashed #333;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-nav ul li {
    width: 50%;
    margin-top: 35px;
    border-left: none;
  }
}

#global_header .header .header-inner .header-nav ul li a {
  color: #333;
  display: block;
  transition: all .3s ease;
}

#global_header .header .header-inner .header-nav ul li a i {
  display: block;
  font-size: 32px;
  margin-bottom: 15px;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-nav ul li a i {
    color: #6fbbf4;
  }
}

#global_header .header .header-inner .header-nav ul li a:hover {
  color: #6fbbf4;
  opacity: 1;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-nav ul li:nth-child(odd) {
    border-right: 3px dashed #333;
  }
}

#global_header .header .header-inner .header-nav ul li:last-child {
  border-right: 3px dashed #333;
}

@media (max-width: 834px) {
  #global_header .header .header-inner .header-nav ul li:last-child {
    border-right: none;
  }
}

#global_header .header .header-inner .header-nav.active {
  left: 0;
}

#global_header .mainphoto {
  max-width: 1200px;
  width: 100%;
  margin-top: 30px;
  position: relative;
  margin: 35px auto 0;
}

@media (max-width: 834px) {
  #global_header .mainphoto {
    margin-top: 80px;
  }
}

#global_header .mainphoto .main-btn {
  position: absolute;
  left: 120px;
  bottom: 140px;
}

@media (max-width: 1200px) {
  #global_header .mainphoto .main-btn {
    bottom: 10.67vw;
    left: 10vw;
  }
}

@media (max-width: 834px) {
  #global_header .mainphoto .main-btn {
    display: none;
  }
}

#global_header .mainphoto .main-btn .main-tel {
  width: 400px;
  height: 60px;
  line-height: 53px;
  background-color: #fff;
  border: 3px solid #ffc000;
  display: block;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
  color: #ffc000;
  transition: all .3s ease;
}

@media (max-width: 1000px) {
  #global_header .mainphoto .main-btn .main-tel {
    width: 40vw;
    height: 6vw;
    line-height: 4.8vw;
  }
}

#global_header .mainphoto .main-btn .main-tel .fa-phone {
  margin-right: 30px;
  font-size: 24px;
  vertical-align: middle;
}

#global_header .mainphoto .main-btn .main-tel .fa-chevron-right {
  margin-left: 40px;
}

#global_header .mainphoto .main-btn .main-tel:hover {
  background-color: #ffc000;
  color: #fff;
  opacity: 1;
}

#global_header .mainphoto .main-btn .main-mail {
  width: 400px;
  height: 60px;
  line-height: 60px;
  background-color: #ffc000;
  color: #fff;
  display: block;
  border-radius: 30px;
  margin-top: 20px;
  font-size: 18px;
  transition: all .3s ease;
}

@media (max-width: 1000px) {
  #global_header .mainphoto .main-btn .main-mail {
    width: 40vw;
    height: 6vw;
    line-height: 5.6vw;
    margin-top: 10px;
  }
}

#global_header .mainphoto .main-btn .main-mail .fa-envelope {
  margin-right: 30px;
  font-size: 24px;
  vertical-align: middle;
}

#global_header .mainphoto .main-btn .main-mail .fa-chevron-right {
  margin-left: 30px;
}

#global_header .mainphoto .main-btn .main-mail:hover {
  background-color: #fff;
  color: #ffc000;
  border: 3px solid #ffc000;
  opacity: 1;
  line-height: 54px;
}

@media (max-width: 640px) {
  #global_header .mainphoto .sp-none {
    display: none;
  }
}

#global_header .mainphoto .sp-block {
  display: none;
}

@media (max-width: 640px) {
  #global_header .mainphoto .sp-block {
    display: block;
    border-radius: 0 0 60px 60px;
  }
}

#global_header .main-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 350px !important;
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;
}

#global_header .main-bg:before {
  position: absolute;
  content: "";
  background-color: #e0f2ae;
  width: 100%;
  height: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#global_header .main-bg img {
  height: 350px !important;
  width: 1920px !important;
  object-fit: cover;
}

@media (max-width: 1000px) {
  #global_header .main-bg img {
    display: none;
  }
}

.res-menu {
  display: none;
}

@media (max-width: 834px) {
  .res-menu {
    display: block;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
    background-color: #6fbbf4;
    cursor: pointer;
    z-index: 999;
  }
}

.res-menu div {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  height: 2px;
  width: 35%;
}

.res-menu div:nth-of-type(1) {
  transform: translateY(-7px);
  transition: all .3s ease;
}

.res-menu div:nth-of-type(3) {
  transform: translateY(7px);
  transition: all .3s ease;
}

.res-menu.active div:nth-of-type(1) {
  transform: rotate(45deg);
}

.res-menu.active div:nth-of-type(2) {
  opacity: 0;
}

.res-menu.active div:nth-of-type(3) {
  transform: rotate(-45deg);
}

#mainArea {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 0;
}

@media (max-width: 834px) {
  #mainArea {
    margin: 80px auto 50px;
  }
}

#mainArea img {
  width: 100% !important;
  height: auto;
  border-radius: 40px;
}

@media (max-width: 834px) {
  #mainArea img {
    border-radius: 0 0 40px 40px;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center;
}

#local-keyvisual:before {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  content: "";
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  position: relative;
  text-align: center;
  color: #111;
  z-index: 2;
  font-size: 45px;
  padding: 180px 0 200px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 40px;
    padding: 120px 0 150px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 34px;
    padding: 90px 0 120px;
  }
}

#local-keyvisual h1:before {
  position: absolute;
  content: "";
  background-color: #ffc000;
  height: 4px;
  width: 160px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 140px;
  border-radius: 2px;
}

@media (max-width: 834px) {
  #local-keyvisual h1:before {
    width: 120px;
    bottom: 110px;
  }
}

@media (max-width: 834px) and (max-width: 640px) {
  #local-keyvisual h1:before {
    width: 80px;
    height: 3px;
    bottom: 80px;
  }
}

#local-keyvisual h1:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
}

@media (max-width: 834px) {
  #local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
    border-radius: 0 0 40px 40px;
  }
}

.pan1 {
  max-width: 1200px;
  margin: 20px auto 40px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.pan1 .pankuzu {
  display: flex;
  justify-content: flex-end;
}

.error,
.confirm {
  max-width: 1200px;
  width: 100%;
  margin: 10px auto;
}

.error {
  margin-top: 20px !important;
}

/*IE変更*/
@media all and (-ms-high-contrast: none) {
  .header-contact-btn,
  .main-tel,
  .main-mail,
  .service-list li,
  .contact-tel a,
  .contact-fax p,
  .contact-mail a {
    padding-top: 5px;
  }
  .service-btn a,
  .magazine-textbox a {
    padding-top: 3px;
  }
  .news-list dt {
    width: 120px !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #fff;
  background-color: #72bcf4;
  padding-top: 60px;
}

@media (max-width: 640px) {
  footer#global_footer {
    padding-top: 40px;
  }
}

footer#global_footer .footer-inner {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 834px) {
  footer#global_footer .footer-inner {
    display: block;
  }
}

footer#global_footer .footer-inner p {
  text-align: left;
  font-size: 24px;
  line-height: 1.2;
}

@media (max-width: 834px) {
  footer#global_footer .footer-inner p {
    text-align: center;
  }
}

footer#global_footer .footer-inner p a {
  color: #fff;
}

@media (max-width: 834px) {
  footer#global_footer .footer-inner .footer-nav {
    width: 100%;
    margin: 20px auto 0;
  }
}

footer#global_footer .footer-inner .footer-nav ul {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  footer#global_footer .footer-inner .footer-nav ul {
    justify-content: space-between;
  }
}

footer#global_footer .footer-inner .footer-nav ul li {
  width: 33%;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 834px) {
  footer#global_footer .footer-inner .footer-nav ul li {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  footer#global_footer .footer-inner .footer-nav ul li {
    width: 50%;
    margin-top: 30px;
  }
}

footer#global_footer .footer-inner .footer-nav ul li a {
  color: #fff;
}

@media (max-width: 640px) {
  footer#global_footer .footer-inner .footer-nav ul li a {
    font-size: 18px;
  }
}

footer#global_footer #copy {
  display: block;
  margin-top: 80px;
  font-size: 14px;
  padding-bottom: 5px;
}

@media (max-width: 834px) {
  footer#global_footer #copy {
    margin-top: 60px;
  }
}

footer#global_footer #copy a {
  color: #fff;
  font-size: 13px;
}

/* box */
html {
  visibility: hidden;
}

html.wf-active, html.loading-delay {
  visibility: visible;
}

.about {
  position: relative;
  background-color: #e0f2ae;
  padding-top: 80px;
  padding-bottom: 160px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 834px) {
  .about {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}

.about h2 {
  font-size: 32px;
  margin-top: 30px;
  margin-bottom: 40px;
  line-height: 1.6;
}

@media (max-width: 1000px) {
  .about h2 {
    font-size: 28px;
  }
}

@media (max-width: 834px) {
  .about h2 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

@media (max-width: 834px) {
  .about .sec1-logo img {
    width: 60px !important;
  }
}

@media (max-width: 640px) {
  .about .sec1-logo img {
    width: 50px !important;
  }
}

.about .about-text {
  line-height: 50px;
  font-size: 18px;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media (max-width: 1000px) {
  .about .about-text {
    font-size: 16px;
    line-height: 45px;
  }
}

.about .sec1-bg {
  position: absolute;
  bottom: -290px;
  left: 0;
}

@media (max-width: 834px) {
  .about .sec1-bg {
    display: none;
  }
}

.about .sec1-bg img {
  width: 100vw !important;
  height: 400px !important;
  object-fit: cover;
}

.news-title,
.insta-title,
.service-title,
.p2-h2 {
  position: relative;
  background-color: #72bcf4;
  padding: 10px 0 12px 20px;
  border-radius: 10px 30px 30px 30px;
  margin-bottom: 40px;
}

@media (max-width: 640px) {
  .news-title,
  .insta-title,
  .service-title,
  .p2-h2 {
    margin-bottom: 30px;
    padding: 15px 0 18px 30px;
  }
}

.news-title:before,
.insta-title:before,
.service-title:before,
.p2-h2:before {
  position: absolute;
  content: "";
  background-color: #72bcf4;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  left: 0;
  top: -25px;
  z-index: 0;
}

.news-title h2,
.insta-title h2,
.service-title h2,
.p2-h2 h2 {
  color: #fff;
  font-size: 26px;
  position: relative;
  z-index: 1;
}

@media (max-width: 640px) {
  .news-title h2,
  .insta-title h2,
  .service-title h2,
  .p2-h2 h2 {
    font-size: 28px;
  }
}

.news-title h2 i,
.insta-title h2 i,
.service-title h2 i,
.p2-h2 h2 i {
  margin-right: 10px;
  font-size: 34px;
}

.news-title h2 span,
.insta-title h2 span,
.service-title h2 span,
.p2-h2 h2 span {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}

.sec2 {
  position: relative;
  margin-top: 80px;
}

.sec2 .inner {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 834px) {
  .sec2 .inner {
    display: block;
  }
}

.sec2 .inner .news {
  width: 49%;
}

@media (max-width: 834px) {
  .sec2 .inner .news {
    width: 100%;
  }
}

.sec2 .inner .news .news-list {
  max-height: 350px;
  overflow-y: scroll;
}

@media (max-width: 640px) {
  .sec2 .inner .news .news-list {
    max-height: 350px;
  }
}

.sec2 .inner .news .news-list dl {
  border-bottom: 2px solid #b7daf5;
  padding: 15px 0 15px 5px;
  flex-direction: column;
}

@media (max-width: 834px) {
  .sec2 .inner .news .news-list dl {
    display: block;
    padding: 12px 0 12px 0;
  }
}

.sec2 .inner .news .news-list dl dt {
  background: none;
  border: none;
  color: #ffc000;
  width: 110px !important;
}

@media (max-width: 640px) {
  .sec2 .inner .news .news-list dl dt {
    padding: 10px;
  }
}

.sec2 .inner .news .news-list dl dd {
  border: none;
  line-height: 1.4;
}

@media (max-width: 640px) {
  .sec2 .inner .news .news-list dl dd {
    margin-left: 5px;
    padding: 5px;
    width: 100% !important;
    font-size: 18px;
  }
}

.sec2 .inner .insta {
  width: 49%;
}

@media (max-width: 834px) {
  .sec2 .inner .insta {
    width: 100%;
    margin-top: 80px;
  }
}

.sec2 .inner .insta .insta-photobox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sec2 .inner .insta .insta-photobox .sns_list {
  width: 33%;
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .sec2 .inner .insta .insta-photobox .sns_list {
    width: 50%;
  }
}

.sec2 .inner .insta .insta-photobox .sns_list .sns_photo {
  width: 100% !important;
}

.sec2 .inner .insta .insta-photobox .sns_list .sns_photo img {
  border-radius: 30px;
}

.sec2 .inner .insta .insta-photobox .sns_list .sns_text {
  display: none;
}

.service {
  background-color: #fbf8e9;
  padding-top: 80px;
  padding-bottom: 200px;
  margin-top: 80px;
}

@media (max-width: 834px) {
  .service {
    padding-top: 60px;
    padding-bottom: 160px;
    margin-top: 60px;
  }
}

.service .inner {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media (max-width: 834px) {
  .service .inner {
    display: block;
  }
}

.service .service-title {
  width: 100%;
  margin-bottom: 40px;
}

.service .service-photo {
  width: 48%;
  margin-right: 20px;
}

@media (max-width: 834px) {
  .service .service-photo {
    width: 70%;
    margin: 0px auto 0;
  }
}

@media (max-width: 640px) {
  .service .service-photo {
    width: 80%;
  }
}

@media (max-width: 640px) {
  .service .service-photo img {
    max-width: 100%;
  }
}

.service .service-textbox {
  margin-left: 20px;
  width: 48%;
  background-color: #fff;
  border-radius: 0 30px 30px 30px;
  padding-bottom: 30px;
  margin-bottom: -20px;
}

@media (max-width: 834px) {
  .service .service-textbox {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
}

@media (max-width: 640px) {
  .service .service-textbox {
    margin-top: 50px;
  }
}

.service .service-textbox .service-list {
  margin-left: 30px;
}

.service .service-textbox .service-list ul li {
  font-size: 18px;
  padding-left: 30px;
  position: relative;
  margin-top: 20px;
}

.service .service-textbox .service-list ul li:before {
  position: absolute;
  left: 10px;
  top: 50%;
  content: "";
  background-color: #ffc000;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.service .service-textbox .service-bottom-text {
  margin-top: 20px;
  line-height: 1.6;
  margin-left: 60px;
}

.service .service-textbox .service-btn a {
  position: relative;
  display: block;
  height: 60px;
  width: 280px;
  line-height: 60px;
  border-radius: 30px;
  text-align: center;
  margin: 40px auto 0;
  background-color: #ffc000;
  color: #fff;
  transition: all .3s ease;
}

@media only screen and (max-width: 640px) {
  .service .service-textbox .service-btn a {
    font-weight: bold;
  }
}

.service .service-textbox .service-btn a:before {
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 5 pro";
  font-weight: bold;
  top: 53%;
  transform: translateY(-50%);
  right: 30px;
}

.service .service-textbox .service-btn a:hover {
  background-color: #fff;
  color: #ffc000;
  border: 3px solid #ffc000;
  line-height: 54px;
}

.sec4 {
  margin-top: -80px;
  width: 100%;
}

.sec4 .inner {
  display: flex;
}

@media (max-width: 834px) {
  .sec4 .inner {
    display: block;
    margin-top: -80px;
  }
}

.sec4 .inner .company {
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  width: 100%;
  height: 100%;
}

@media (max-width: 834px) {
  .sec4 .inner .company {
    width: 100%;
    margin: 20px auto 0;
  }
}

.sec4 .inner .company:before {
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 5 pro";
  font-weight: bold;
  top: 53%;
  transform: translateY(-50%);
  right: 40px;
  color: #6fbbf4;
  font-size: 26px;
}

.sec4 .inner .company a {
  width: 100% !important;
  height: 100% !important;
}

.sec4 .inner .company a img {
  width: 100% !important;
}

.sec4 .inner .company h2 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  font-size: 38px;
  width: 200px;
}

@media (max-width: 1200px) {
  .sec4 .inner .company h2 {
    font-size: 3.17vw;
  }
}

@media (max-width: 1200px) and (max-width: 834px) {
  .sec4 .inner .company h2 {
    font-size: 5vw;
  }
}

.sec4 .inner .company h2 span {
  color: #6fbbf4;
  font-size: 20px;
}

@media (max-width: 1200px) {
  .sec4 .inner .company h2 span {
    font-size: 1.67vw;
  }
}

@media (max-width: 1200px) and (max-width: 834px) {
  .sec4 .inner .company h2 span {
    font-size: 3vw;
  }
}

.sec4 .inner .company img {
  max-width: 100%;
}

.magazine {
  margin-top: 80px;
  position: relative;
  max-width: 1000px;
  margin: 100px auto 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 834px) {
  .magazine {
    margin-top: 20px;
  }
}

.magazine .magazine-img {
  min-height: 220px;
}

.magazine .magazine-img img {
  min-height: 220px;
  object-fit: cover;
}

@media (max-width: 834px) {
  .magazine .magazine-img img {
    border-radius: 50px;
  }
}

.magazine .magazine-textbox {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 834px) {
  .magazine .magazine-textbox {
    top: 51%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.magazine .magazine-textbox h2 {
  font-size: 38px;
  position: relative;
}

@media (max-width: 834px) {
  .magazine .magazine-textbox h2 {
    font-size: 4.8vw;
  }
}

.magazine .magazine-textbox h2:before {
  position: absolute;
  content: "";
  background-color: #ffc000;
  width: 100px;
  height: 3px;
  border-radius: 3px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -30px;
}

@media (max-width: 834px) {
  .magazine .magazine-textbox h2:before {
    bottom: -20px;
    height: 2px;
    width: 60px;
  }
}

.magazine .magazine-textbox div {
  margin-top: 60px;
}

@media (max-width: 834px) {
  .magazine .magazine-textbox div {
    margin-top: 7vw;
  }
}

.magazine .magazine-textbox div a {
  position: relative;
  display: block;
  width: 280px;
  height: 55px;
  line-height: 55px;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  background-color: #ffc000;
  margin: 50px auto 0;
  transition: all .3s ease;
}

@media (max-width: 834px) {
  .magazine .magazine-textbox div a {
    margin-top: 5.2vw;
    height: 50px;
    width: 200px;
    line-height: 48px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 640px) {
  .magazine .magazine-textbox div a {
    font-weight: bold;
  }
}

.magazine .magazine-textbox div a:before {
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 5 pro";
  font-weight: bold;
  top: 53%;
  transform: translateY(-50%);
  right: 30px;
}

.magazine .magazine-textbox div a:hover {
  background-color: #fff;
  color: #ffc000;
  border: 3px solid #ffc000;
  line-height: 44px;
}

.sec6 {
  margin-top: 80px;
}

@media (max-width: 834px) {
  .sec6 {
    margin-top: 20px;
  }
}

.sec6 .inner {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .sec6 .inner {
    display: block;
  }
}

.sec6 .inner .contact {
  background-color: #f3f3f3;
  padding: 80px 30px 50px;
  width: 48%;
  border-radius: 60px;
  height: 400px;
}

@media (max-width: 1000px) {
  .sec6 .inner .contact {
    width: 100%;
    padding: 60px 20px;
  }
}

@media (max-width: 834px) {
  .sec6 .inner .contact {
    padding: 50px 20px;
    height: 350px;
  }
}

.sec6 .inner .contact .contact-title {
  font-size: 28px;
  position: relative;
}

@media (max-width: 834px) {
  .sec6 .inner .contact .contact-title {
    font-size: 26px;
  }
}

.sec6 .inner .contact .contact-title:before {
  position: absolute;
  content: "";
  background-color: #ffc000;
  width: 140px;
  height: 3px;
  border-radius: 3px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -30px;
}

.sec6 .inner .contact .contact-info {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sec6 .inner .contact .contact-info div {
  width: 49%;
  margin-top: 10px;
}

.sec6 .inner .contact .contact-info div div {
  width: 100%;
}

.sec6 .inner .contact .contact-info div a {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
}

@media (max-width: 834px) {
  .sec6 .inner .contact .contact-info div a {
    height: 65px;
    line-height: 65px;
  }
}

@media only screen and (max-width: 640px) {
  .sec6 .inner .contact .contact-info div a {
    font-weight: bold;
  }
}

.sec6 .inner .contact .contact-info div .fa-chevron-right {
  margin-left: 15px;
}

.sec6 .inner .contact .contact-info .contact-tel a {
  background-color: #72bcf4;
  color: #fff;
  transition: all .3s ease;
}

.sec6 .inner .contact .contact-info .contact-tel a:hover {
  background-color: #fff;
  color: #72bcf4;
  line-height: 59px;
  opacity: 1;
  border: 3px solid #72bcf4;
}

.sec6 .inner .contact .contact-info .contact-tel .fa-phone {
  margin-right: 15px;
  font-size: 20px;
  margin-top: 5px;
}

.sec6 .inner .contact .contact-info .contact-fax p {
  background-color: #fff;
  color: #72bcf4;
  border: 3px solid #72bcf4;
  line-height: 60px;
  height: 65px;
  transition: all .3s ease;
  border-radius: 30px;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .sec6 .inner .contact .contact-info .contact-fax p {
    font-weight: bold;
  }
}

.sec6 .inner .contact .contact-info .contact-fax p:hover {
  background-color: #72bcf4;
  color: #fff;
  line-height: 59px;
  opacity: 1;
}

.sec6 .inner .contact .contact-info .contact-fax .fa-fax {
  margin-right: 15px;
  font-size: 20px;
}

.sec6 .inner .contact .contact-info .contact-mail a {
  background-color: #ffc000;
  color: #fff;
  transition: all .3s ease;
}

.sec6 .inner .contact .contact-info .contact-mail a:hover {
  background-color: #fff;
  color: #ffc000;
  border: 3px solid #ffc000;
  line-height: 59px;
}

.sec6 .inner .contact .contact-info .contact-mail .fa-envelope {
  margin-right: 15px;
  font-size: 20px;
  margin-top: 5px;
}

.sec6 .inner .contact .contact-info .contact-address {
  line-height: 1.8;
}

.sec6 .inner .top-map {
  border-radius: 60px;
  width: 48%;
}

@media (max-width: 1000px) {
  .sec6 .inner .top-map {
    width: 100%;
  }
}

.sec6 .inner .top-map iframe {
  border-radius: 60px;
  height: 400px;
}

@media (max-width: 1000px) {
  .sec6 .inner .top-map iframe {
    width: 100%;
    height: 350px;
    margin-top: 40px;
  }
}

.p2-h2 {
  position: relative;
  background-color: #72bcf4;
  padding: 10px 0 12px 20px;
  border-radius: 10px 30px 30px 30px;
  margin-bottom: 40px;
  margin-top: 60px;
}

@media (max-width: 640px) {
  .p2-h2 {
    margin-bottom: 30px;
    padding: 15px 0 15px 30px;
  }
}

.p2-h2:before {
  position: absolute;
  content: "";
  background-color: #72bcf4;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  left: 0;
  top: -25px;
  z-index: 0;
}

.p2-h2 h2 {
  color: #fff;
  font-size: 26px;
  position: relative;
  z-index: 1;
}

@media (max-width: 640px) {
  .p2-h2 h2 {
    font-size: 28px;
  }
}

.p2-h2 h2 i {
  margin-right: 10px;
  font-size: 34px;
}

.p2-h2 h2 span {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}

.p2-logo {
  display: flex;
  align-items: center;
  text-align: left;
}

@media (max-width: 834px) {
  .p2-logo {
    margin-left: 20px;
  }
}

.p2-logo img {
  margin-right: 10px;
}

@media (max-width: 834px) {
  .p2-logo img {
    width: 45px;
  }
}

.p2-logo a {
  color: #333;
  font-size: 22px;
  line-height: 28px;
}

@media (max-width: 834px) {
  .p2-logo a {
    font-size: 18px;
    line-height: 24px;
  }
}

.p2-logo span {
  display: block;
}

.p2-inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

/*サービス内容*/
.p2-h3 {
  font-size: 28px;
  margin-top: 50px;
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
  padding-left: 20px;
  font-weight: bold;
  z-index: 1;
  line-height: 1.4;
}

.p2-h3:after {
  position: absolute;
  content: "";
  background: #ffc000;
  width: 5px;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  visibility: visible;
  z-index: -1;
  border-radius: 5px;
}

.p2-h2-2 {
  margin-bottom: 0;
  margin-top: 40px;
}

.service-top-text {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 2;
}

.service-top-text span {
  position: relative;
  margin-left: 20px;
  display: inline-block;
  margin-top: 5px;
}

.service-top-text span:before {
  position: absolute;
  content: "";
  background-color: #ffc000;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 52%;
  transform: translateY(-50%);
  left: -15px;
}

.service-top-text .bold {
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 20px;
  margin-left: -20px;
}

.service-top-text .bold span {
  color: #72bcf4;
  margin-right: 10px;
}

.service-top-text .bold span:before {
  display: none;
}

.service-top-text .bold2 {
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 20px;
  margin-left: -20px;
}

.service-top-text .bold2 span {
  color: #72bcf4;
  margin-right: 10px;
}

.service-top-text .bold2 span:before {
  display: none;
}

.service-bottom-text {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 2;
}

.flow-box {
  position: relative;
  display: flex;
  max-width: 1100px;
  align-items: center;
  margin: 5px auto 0;
  background-color: #f7f7f7;
  padding: 20px 20px 20px 50px;
  border-radius: 20px;
  margin-left: 60px;
  margin-right: 10px;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.4;
}

.flow-box:before {
  position: absolute;
  content: "";
  background: rgba(114, 188, 244, 0.8);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
}

.flow-box:after {
  position: absolute;
  content: "step1";
  color: #fff;
  font-size: 18px;
  left: -42px;
  white-space: pre;
  text-align: center;
  line-height: 1.4;
  top: 52%;
  transform: translateY(-50%);
}

.flow-box .flow1-img {
  width: 120px;
}

@media (max-width: 640px) {
  .flow-box .flow1-img {
    width: 80px;
  }
}

.flow-box .flow-text {
  width: 70%;
  margin-left: 60px;
}

@media (max-width: 640px) {
  .flow-box .flow-text {
    margin-left: 30px;
  }
}

.flow-box .flow-text h3 {
  font-size: 20px;
  margin-bottom: 30px;
}

@media (max-width: 640px) {
  .flow-box .flow-text h3 {
    margin-bottom: 15px;
  }
}

.flow-box .flow-text div {
  line-height: 1.6;
  font-size: 14px;
}

.flow-box1:after {
  content: "STEP \A 1";
}

.flow-box2:after {
  content: "STEP \A 2";
}

.flow-box3:after {
  content: "STEP \A 3";
}

.flow-box4:after {
  content: "STEP \A 4";
}

.flow-box5:after {
  content: "STEP \A 5";
}

.flow-box6:after {
  content: "STEP \A 6";
}

.flow-box7:after {
  content: "STEP \A 7";
}

.flow-box8:after {
  content: "STEP \A 8";
}

/*会社概要*/
.company-list {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media (max-width: 640px) {
  .company-list dl {
    display: block !important;
  }
}

.company-list dl dt {
  padding: 18px 10px !important;
}

@media (max-width: 640px) {
  .company-list dl dt {
    width: 100% !important;
    border-right: 1px solid #ccc !important;
  }
}

.company-list dl dd {
  padding: 18px 10px !important;
  line-height: 1.4;
}

.company-list dl dd a {
  color: #72bcf4;
}

@media (max-width: 640px) {
  .company-list dl dd {
    width: 100% !important;
  }
}

.company-list dl dd iframe {
  max-width: 600px;
  width: 100%;
  height: 250px;
}

/*採用情報*/
.recruit-top-text {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 80px;
  margin-left: 10px;
}

.recruit-top-text p {
  font-size: 22px;
  color: #ffc000;
  margin-bottom: -20px;
}

.recruit-top-text span {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 10px;
}

.recruit-list {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media (max-width: 640px) {
  .recruit-list dl {
    display: block !important;
  }
}

.recruit-list dl dt {
  padding: 15px 10px !important;
}

@media (max-width: 640px) {
  .recruit-list dl dt {
    width: 100% !important;
  }
}

.recruit-list dl dd {
  padding: 15px 10px !important;
  line-height: 1.5;
}

@media (max-width: 640px) {
  .recruit-list dl dd {
    width: 100% !important;
  }
}

.recruit-list2 dl {
  display: block !important;
}

.recruit-list2 dl dt {
  width: 100% !important;
  padding: 16px !important;
}

.recruit-list2 dl dd {
  width: 100% !important;
  padding: 16px !important;
  border-bottom: 1px solid #ccc !important;
}

.recruit-list2 dl dd:nth-of-type(odd) {
  border-right: none;
}

.recruit-list2 dl dd:nth-of-type(even) {
  border-left: none !important;
}

.recruit-list2 dl .recruit-list-dt {
  border-right: 1px solid #ccc !important;
}

.recruit-list2 dl .recruit-list-dd {
  border-top: none !important;
  border-right: 1px solid #ccc !important;
}

/*実りだより*/
.p2-insta .sns_list {
  margin-top: 60px;
  border: none !important;
  border-bottom: 1px dashed #999 !important;
  padding-bottom: 40px;
}

.p2-insta .sns_list .sns_photo {
  width: 35% !important;
}

@media only screen and (max-width: 834px) {
  .p2-insta .sns_list .sns_photo {
    width: 80% !important;
    margin: 0 auto;
  }
}

.p2-insta .sns_list .sns_text {
  margin-top: 20px;
}

.p2-insta .sns_list .sns_text .sns_date {
  font-size: 20px;
  font-weight: bold;
}

.p2-insta .sns_list .sns_text .caption {
  font-size: 18px;
  margin-top: 40px;
}

.p2-insta .sns_list .sns_text ul {
  margin-top: 15px;
}

.p2-insta .sns_list .sns_text ul li a {
  font-size: 14px;
}

/*お問い合わせ*/
.mail-text {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.mail-form {
  margin-top: 40px;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media (max-width: 670px) {
  .mail-form dl {
    display: block !important;
  }
}

.mail-form dl dt {
  padding: 15px 0 !important;
  flex-direction: row !important;
  align-items: center !important;
}

@media (max-width: 670px) {
  .mail-form dl dt {
    width: 100% !important;
    border-right: 1px solid #ccc !important;
    padding: 25px 0 !important;
  }
}

.mail-form dl dd {
  padding: 10px !important;
}

@media (max-width: 670px) {
  .mail-form dl dd {
    width: 100% !important;
  }
  .mail-form dl dd input {
    height: 100% !important;
  }
}

.mail-form dl .text input {
  width: 100% !important;
  height: 200px;
}

.mail-form .tel dt {
  color: #333;
  pointer-events: none !important;
}

.mail-text {
  border-bottom: 1px dashed #6fbbf4;
  padding-bottom: 30px;
  line-height: 1.6;
  padding-left: 10px;
}

.mail-text a {
  color: #ffc000;
  margin-top: 10px;
  font-size: 18px;
  display: inline-block;
  margin-top: 10px;
}

.privacy-policy {
  text-align: center;
  margin-top: 10px;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media (max-width: 640px) {
  .privacy-policy {
    font-size: 14px;
  }
}

.privacy-policy a {
  color: #ffc000;
  font-weight: bold;
}

.error-text {
  font-size: 18px;
  line-height: 1.8;
  margin-top: 50px;
}

.mail-address {
  color: #6fbbf4 !important;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.modaal-content-container {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.6;
}

.annot {
  display: none;
}

.mail {
  display: none;
}
